/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* In your component's CSS file */
.toggle-checked {
  --toggle-checked-bar-color: #009CFF !important; /* Toggle bar color when checked */
  --toggle-checked-button-color: #03B8DB !important; /* Toggle button color when checked */
}

.toggle-unchecked {
  --toggle-unchecked-bar-color: #EBEBEB; /* Toggle bar color when unchecked */
  --toggle-unchecked-button-color: #FF7C10; /* Toggle button color when unchecked */
}


::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: green;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: green;
}

.cdk-overlay-container {
  &.index-0 {
    top: 20px;
  }
  &.index-1 {
    top: 80px; // Adjust based on the height of the snackbar
  }
  //margin-top: 4% !important;
}

.custom-snackbar {
  padding: 10px;
  border-radius: 8px;
  background-color: #333;
  color: #fff;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #009CFF !important;
  opacity: 80% !important;
}

.mat-mdc-snack-bar-container {
  margin-top: 2% !important;
  position: static;
  background-color: #EFEFEF;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #000000 !important;
  //background-color: #FF9F50 !important;
  --mat-text-button-state-layer-color: currentColor;
  --mat-text-button-ripple-color: currentColor;
}

.stats-cards {
  max-width: 100%;
  //background-color: #CCCCCC;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  display: grid;
  //gap: 2%;
}
